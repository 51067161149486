import styled, { css } from "styled-components";
import { Breakpoints, Horizontal, Typography } from "../../constants/constants";
import getFontValue from "../../utils/getFontValue";
import {
  EmblaCarousel,
  EmblaContainer,
  EmblaSlide,
  EmblaSlideInner,
  NextButtonElement,
  PrevButtonElement
} from "../GenericCarousel/GenericCarouselStyled";
import { getFontSize } from "@serenaandlily/utils";

const defaultSlideHeight = 600;

export const RecommendationCarouselWrapper = styled.div`
  margin-bottom: ${({ carouselMarginBottom }) =>
    carouselMarginBottom ? `${carouselMarginBottom}px` : "24px"};
  padding-top: 24px;
  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
    padding-top: 32px;
    margin-bottom: ${({ carouselMarginBottom }) =>
      carouselMarginBottom ? `${carouselMarginBottom}px` : "32px"};
    padding-left: 8px;
    padding-right: 8px;
  }
  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    ${({ containerMargin }) => containerMargin && `margin: ${containerMargin};`}
    margin-bottom: ${({ carouselMarginBottom }) =>
      carouselMarginBottom ? `${carouselMarginBottom}px` : "60px"};
    padding-top: 60px;
  }
`;

const getVerticalText = (verticalText, textDistance) => {
  return css`
    :before {
      position: absolute;
      content: "${verticalText}";
      right: ${textDistance}px;
      top: 50%;
      transform: translateY(-50%);
      writing-mode: vertical-rl;
      text-transform: uppercase;
      white-space: nowrap;
      font-family: ${Typography.PPFragment};
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 2px;
      line-height: 1.43;
    }
  `;
};

export const RecommendationCarouselContainer = styled.div`
  ${({
    totalMargin,
    visibleSlides,
    slideMargin,
    verticalText,
    leftOffset,
    rightOffset,
    textDistance,
    marginDesktop,
    marginMobileStyles
  }) => css`
    @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
      padding: 0;
    }
    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      margin: ${marginDesktop ? `${marginDesktop}` : "0"};
    }

    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      padding: 0;
    }

    @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
      padding: 0 0;
    }
    ${marginMobileStyles}

    ${EmblaSlide} {
      min-width: unset;
      flex: 0 0 253px;

      margin-left: ${slideMargin}px;

      @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
        flex: 0 0 284px;
      }
      @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
        flex: 0 0
          calc(
            (100% - (${visibleSlides} - 1) * ${slideMargin}px) /
              ${visibleSlides} - 0.1px
          );
        &:last-child {
          margin-left: calc(${slideMargin}px - 0.2px);
        }
      }
      & > ${EmblaSlideInner} {
        height: unset;
        display: flex;
      }
    }
    @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
      ${PrevButtonElement} {
        left: ${leftOffset}px;
      }

      ${NextButtonElement} {
        right: ${rightOffset}px;

        ${verticalText && getVerticalText(verticalText, textDistance)}
      }
    }
  `}
`;

export const CarouselHeadline = styled.h2`
  ${({
    fontSize,
    textColor,
    fontSizeTablet,
    fontSizeDesktop,
    headerPosition,
    headerPositionTablet,
    headerPositionDesktop,
    bottomMargin
  }) => css`
    display: flex;
    font-family: ${({ fontFamily }) => `${getFontValue(fontFamily)}`};
    font-weight: normal;
    ${fontSize && (fontSizeTablet || fontSizeDesktop)
      ? getFontSize(
          {
            fontSize: fontSize,
            fontSizeTablet: fontSizeTablet,
            fontSizeDesktop: fontSizeDesktop
          },
          null,
          true
        )
      : `font-size: 1.5em;`}

    ${headerPosition &&
    css`
      justify-content: ${Horizontal[headerPosition] || "center"};
    `}

    ${headerPositionTablet &&
    css`
      @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
        justify-content: ${Horizontal[headerPositionTablet] || "center"};
      }
    `}
    ${headerPositionDesktop &&
    css`
      @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
        justify-content: ${Horizontal[headerPositionDesktop] || "center"};
      }
    `}

    ${bottomMargin
      ? css`
          margin-bottom: ${bottomMargin}px;
        `
      : css`
          margin-bottom: 16px;

          @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
            margin-bottom: 24px;
          }
          @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
            margin-bottom: 40px;
          }
        `}
    margin-top:0;
    color: ${textColor};
  `}
`;

export const StationarySlidesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProductTileWrapper = styled.div`
  width: 196px;
  margin: 0;
  &:not(:last-of-type) {
    margin-right: 20px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => `${height || defaultSlideHeight}px`};
`;
