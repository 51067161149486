import { useState } from "react";
import { constructorHelpers } from "../../utils";
import { useRouter } from "next/router";
import { useCart } from "../../contexts/CartContext";
import useSWRImmutable from "swr/immutable";
import laggy from "../../middleware";
import useUser from "@lib/useUser";
import { useWishlistUserCreds } from "@serenaandlily/hooks/useWishlistUserCreds";

export const useRecommendation = () => {
  const [recommendationArgs, setRecommendationArgs] = useState(null);
  const { cartItems } = useCart();
  const router = useRouter();
  const { user } = useUser();

  const { wishlistUserCreds } = useWishlistUserCreds();

  const fetchRecommendationResult = async () => {
    if (!recommendationArgs || !user?.data?.cartId) return null;
    if (
      router.pathname !== "/shoppingbag" ||
      cartItems.length ||
      (!cartItems.length && recommendationArgs?.podIdEmptyCart)
    ) {
      try {
        const { podId, podIdEmptyCart, itemIds, filters, resultsCount } =
          recommendationArgs;
        const data = await constructorHelpers.getRecommender({
          pod:
            router.pathname === "/shoppingbag" &&
            (!cartItems.length || !itemIds?.length)
              ? podIdEmptyCart
              : podId,
          itemIds: itemIds,
          filters,
          resultsCount: resultsCount,
          ...wishlistUserCreds
        });
        return data;
      } catch (e) {
        //eslint-disable-next-line  no-console
        console.log(e);
      }
    }
  };

  const { data: recommendationResult, isValidating: isLoading } =
    useSWRImmutable(
      `recommendations${JSON.stringify(recommendationArgs)}${
        wishlistUserCreds?.guestToken
      }${wishlistUserCreds?.customerEmail}`,
      fetchRecommendationResult,
      {
        use: [laggy]
      }
    );

  return {
    recommendationResult,
    isLoading,
    setRecommendationArgs
  };
};
