import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTrackGA4ViewItemList } from "@serenaandlily/hooks/analytics/useGA4";
import GenericCarousel from "../GenericCarousel";
import {
  RecommendationCarouselContainer,
  CarouselHeadline,
  StationarySlidesContainer,
  RecommendationCarouselWrapper,
  LoadingContainer
} from "./RecommendationCarouselStyled";
import { useBreakpoint } from "../Breakpoints";
import { getDesktopMarginValue } from "../../utils/getMarginValue";
import { getMobileCarouselMargins } from "../Carousel/utils/getMobileCarouselMargins";
import getContentFromSchema from "../../utils/getContentFromSchema/getContentFromSchema";
import { useRecommendation } from "../../hooks";
import searchToProductTile from "../../utils/searchToProductTile/searchToProductTile";
import ICON_TYPES from "../Icons/constants";
import Icons from "../Icons";

const carouselDefaults = {
  mobile: {
    slideMargin: 16
  },
  tablet: {
    slideMargin: 16
  },
  desktop: {
    slideMargin: 40,
    containerMargin: "0 62px"
  }
};
const getCarouselDefaults = ({ isMobile, isTablet }) => {
  if (isMobile) {
    return carouselDefaults.mobile;
  }
  if (isTablet) {
    return carouselDefaults.tablet;
  }
  return carouselDefaults.desktop;
};

const mobileCarouselConfigs = {
  arrows: false,
  dragFree: true,
  useProgressBar: true,
  align: "start"
};
const tabletCarouselConfigs = {
  arrows: false,
  dragFree: true,
  useProgressBar: true,
  loop: false,
  autoplay: false,
  align: "start"
};

const getCarouselConfigs = ({ isMobile, isTablet, desktopCarouselConfigs }) => {
  if (isMobile) return mobileCarouselConfigs;
  if (isTablet) return tabletCarouselConfigs;
  return desktopCarouselConfigs;
};

//eslint-disable-next-line max-lines-per-function, complexity
const RecommendationCarousel = ({
  size,
  sizeMobile,
  carouselMarginBottom = 0,
  displayArrows,
  hideLeftArrow,
  displayVerticalCopy,
  verticalCopy,
  autoplay,
  carouselHeader,
  podId,
  podIdEmptyCart,
  itemIds,
  variationIds,
  carouselHeaderEmptyCart,
  arrowType = "carrot",
  progressBar,
  productTitleConfigs,
  priceConfigs,
  moreTextConfigs,
  showSwatches = false,
  swatchType = "square",
  tileCount,
  imageProductCallout = false,
  hideCta = false,
  imageCta,
  slides = [],
  filters
}) => {
  const [carouselSlides, setCarouselSlides] = useState([]);
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["XSMobileMin"] && breakpoints["XSMobileMax"];
  const isTablet = breakpoints["SMTabletMin"] && breakpoints["SMTabletMax"];

  const minVisibleSlides = 2;
  const maxVisibleSlides = 4;

  const visibleSlides = isMobile ? minVisibleSlides : maxVisibleSlides;
  const { slideMargin, containerMargin } =
    getCarouselDefaults({ isMobile, isTablet }) || {};
  const totalMargin = slideMargin * (visibleSlides - 1);
  const arrowOffset = -40;
  const textDistance = 35;
  const verticalText = displayVerticalCopy ? verticalCopy : null;
  const rightOffset = verticalText ? arrowOffset - textDistance : arrowOffset;

  for (const slide of carouselSlides) {
    if (getContentFromSchema(slide?._meta?.schema) === "CarouselImage") {
      slide.layout = "intrinsic";
    }
  }

  const desktopCarouselConfigs = {
    arrows: displayArrows,
    hideLeftArrow,
    autoplay: carouselSlides.length > maxVisibleSlides ? autoplay : false,
    loop: carouselSlides.length > maxVisibleSlides ? autoplay : false,
    align: "start",
    arrowType,
    progressBar,
    slidesToScroll: 1,
    containScroll: "trimSnaps"
  };
  const carouselConfigs = getCarouselConfigs({
    isMobile,
    isTablet,
    desktopCarouselConfigs
  });

  const marginDesktop = getDesktopMarginValue(size || "Full Width");
  const marginMobileStyles = getMobileCarouselMargins(sizeMobile);

  const header =
    !itemIds?.length && carouselHeaderEmptyCart
      ? carouselHeaderEmptyCart
      : carouselHeader;

  const {
    fontFamily,
    fontSize,
    fontSizeTablet,
    fontSizeDesktop,
    fontColor,
    modularScaleSize,
    bottomMargin,
    headerPosition,
    headerPositionTablet,
    headerPositionDesktop,
    headline
  } = header || {};

  const { recommendationResult, setRecommendationArgs, isLoading } =
    useRecommendation();

  useEffect(() => {
    setRecommendationArgs({
      podId,
      podIdEmptyCart,
      itemIds,
      variationIds,
      resultsCount: tileCount
    });
  }, [itemIds, variationIds, podId]);

  useEffect(() => {
    setRecommendationArgs({
      podId,
      podIdEmptyCart,
      filters,
      itemIds,
      variationIds,
      resultsCount: tileCount
    });
  }, [filters]);

  useEffect(() => {
    const tileConfigs = {
      productTitleConfigs,
      priceConfigs,
      moreTextConfigs,
      swatchType,
      imageProductCallout,
      imageCta,
      source: "recommendation_carousel"
    };
    const tiles = searchToProductTile({
      results: { results: recommendationResult },
      showColorBuckets: showSwatches,
      tileConfigs,
      hideCta
    });
    setCarouselSlides([...tiles, ...slides]);
  }, [recommendationResult]);

  useTrackGA4ViewItemList(carouselSlides, {
    itemListName: `recommendation: ${headline}`,
    itemListId: podId || podIdEmptyCart || "recommendation"
  });
  return (
    <Fragment>
      {carouselSlides.length > 0 && (
        <RecommendationCarouselWrapper
          carouselMarginBottom={carouselMarginBottom}
          containerMargin={containerMargin}
        >
          <CarouselHeadline
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontSizeTablet={fontSizeTablet}
            fontSizeDesktop={fontSizeDesktop}
            headerPosition={headerPosition}
            headerPositionTablet={headerPositionTablet}
            headerPositionDesktop={headerPositionDesktop}
            textColor={fontColor}
            modularScaleSize={modularScaleSize}
            bottomMargin={bottomMargin}
          >
            {headline}
          </CarouselHeadline>

          <RecommendationCarouselContainer
            visibleSlides={visibleSlides}
            slideMargin={slideMargin}
            totalMargin={totalMargin}
            verticalText={verticalText}
            leftOffset={arrowOffset}
            rightOffset={rightOffset}
            textDistance={textDistance}
            marginDesktop={marginDesktop}
            marginMobileStyles={marginMobileStyles}
            data-pdpprint="false"
          >
            {carouselSlides.length > 4 ||
              (isMobile && carouselSlides.length > 2) ? (
              <GenericCarousel
                carouselContent={carouselSlides}
                carouselConfigs={carouselConfigs}
                id={podId}
                list={`recommendation: ${headline}`}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            ) : (
              <StationarySlidesContainer>
                <GenericCarousel
                  carouselContent={carouselSlides}
                  carouselConfigs={carouselConfigs}
                  id={podId}
                  list={`recommendation: ${headline}`}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
              </StationarySlidesContainer>
            )}
          </RecommendationCarouselContainer>
        </RecommendationCarouselWrapper>
      )}
      {isLoading && (
        <LoadingContainer height={carouselConfigs?.height}>
          <Icons type={ICON_TYPES.CircularLoader} />
        </LoadingContainer>
      )}
    </Fragment>
  );
};

export default RecommendationCarousel;

RecommendationCarousel.propTypes = {
  size: PropTypes.string,
  sizeMobile: PropTypes.string,
  carouselMarginBottom: PropTypes.number,
  displayArrows: PropTypes.bool,
  displayVerticalCopy: PropTypes.bool,
  verticalCopy: PropTypes.string,
  autoplay: PropTypes.bool,
  slides: PropTypes.array,
  carouselHeader: PropTypes.object,
  carouselHeaderEmptyCart: PropTypes.object,
  podId: PropTypes.string,
  itemIds: PropTypes.array,
  variationIds: PropTypes.array,
  arrowType: PropTypes.string,
  progressBar: PropTypes.object,
  productTitleConfigs: PropTypes.object,
  priceConfigs: PropTypes.object,
  moreTextConfigs: PropTypes.object,
  showSwatches: PropTypes.bool,
  swatchType: PropTypes.string,
  tileCount: PropTypes.number,
  imageProductCallout: PropTypes.bool,
  hideCta: PropTypes.bool,
  imageCta: PropTypes.object,
  masterCollection: PropTypes.array,
  hideLeftArrow: PropTypes.bool
};
